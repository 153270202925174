// AITherapyNeedPage.js

import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

function AITherapyNeedPage() {
  return (
    <Container className="mt-4 mb-4">
<Row>
  <Col>
    <Card className="mb-4 custom-bg-color">
      <Card.Body>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.7rem' }}>
                      The Transformative Potential of AI-Powered Therapy
                    </Card.Title>

                  <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                    <p>
                      The advent of AI-powered therapy marks a groundbreaking shift in mental healthcare, promising not just to expand access but to revolutionize the field's effectiveness. Beyond the pivotal role of making therapy more affordable and widely available, AI-powered therapy introduces a new paradigm in therapeutic engagement—characterized by high-frequency interaction, extensive and specialized expertise, profound personalization, proactive support, and unmatched availability.
                    </p>
                  </Card.Text>
                </Col>
                <Col md={4}>
                  <Image src="ai_therapist.png" alt="Transformative Potential" fluid />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.5rem' }}>
                The Global Challenge of Mental Health
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  Mental health disorders are a widespread challenge, indiscriminately affecting people of all ages, backgrounds, and regions worldwide. The World Health Organization (WHO) reports that almost 1 billion people globally, or one in eight individuals, are living with a mental disorder at any given time, with one in four people likely to experience mental illness at some point in their lives (World Health Organization, 2013). Anxiety and depression rank as the most prevalent issues, underscoring a critical global health concern.
                </p>
                <p>
                  Despite the increasing awareness of mental health's importance, conventional healthcare systems struggle to keep pace with the growing demand for mental health services. The landscape is fraught with obstacles: prohibitive costs, a scarcity of qualified professionals, societal stigma, insufficient awareness, and a pervasive belief in the self-resolution of symptoms all contribute to the underutilization of available resources.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Row>
                <Col md={8}>
                  <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                    The Prevailing Gap in Treatment
                  </Card.Title>
                  <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                    <p>
                      This array of barriers significantly hinders access to necessary care, leading to prolonged suffering and deteriorating mental health conditions for many.
                    </p>
                    <ul>
                      <li>
                        Of the 61.2 million adults in the U.S. diagnosed with mental illness in 2019, only 43.8% received any form of mental health services according to the Substance Abuse and Mental Health Services Administration, (2020).
                      </li>
                      <li>
                        Mental Health America (2021) reveals an even more disturbing statistic: two-thirds of individuals with mental health conditions are left untreated.
                      </li>
                      <li>
                        The disparity is still more pronounced in low- and middle-income countries, where access to mental healthcare is severely limited, leaving the vast majority without any form of treatment (World Health Organization, 2013).
                      </li>
                    </ul>
                  </Card.Text>
                </Col>
                <Col md={4}>
                  <Image src="therapist.png" alt="Prevailing Gap" fluid />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                Delayed Intervention & The Cost of Inaction
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  The formidable barriers to accessing mental health support mean that many only seek help when in crisis.
                </p>
                <ul>
                  <li>
                    Research by Wang et al. (2005) indicates an alarming delay of 8-10 years in seeking treatment for anxiety and depressive disorders from the onset of symptoms.
                  </li>
                  <li>
                    A more recent study by Rodríguez et al. (2012) found that 85% of individuals with depression showed subclinical symptoms for 1-4 years before receiving a diagnosis.
                  </li>
                </ul>
                <p>
                  These findings underscore the critical importance of proactive and early intervention strategies to mitigate the escalation of mental health conditions.
                </p>
                <p>
                  The repercussions of unaddressed mental health issues are severe, with delayed treatment exacerbating symptoms, heightening the risk of additional health problems, and significantly diminishing quality of life. Economically, the stakes are equally high: untreated mental health disorders are projected to impose a global cost of $16 trillion by 2030 (Lancet Commission on Global Mental Health and Sustainable Development, 2018), highlighting an urgent need for effective, scalable solutions. 
                </p>
                <p>
                  In this context, AI-powered therapy emerges not only as a viable option but as a necessary evolution in addressing the multifaceted challenges of mental healthcare delivery, setting the stage for a future where quality mental health services are accessible to all who need them.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.5rem' }}>
                Rethinking Mental Healthcare: The AI Revolution
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  The traditional mental healthcare model, primarily reliant on in-person therapy sessions, has long been the cornerstone of mental health treatment. However, this model faces critical challenges—high costs, limited access, and infrequent sessions hinder its effectiveness and reach. Many individuals, especially those without insurance or in underserved areas, find these services out of reach. The lengthy intervals between sessions, dictated by therapist availability and high demand, further delay recovery, making the need for an innovative approach apparent.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                The Dawn of AI-Powered Therapy
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  Recent advancements in artificial intelligence have introduced AI-powered therapy as a groundbreaking alternative. By harnessing machine learning, these platforms offer personalized, accessible, and cost-effective mental health support. The development of the Transformers architecture in 2018, and the arrival of Large Language Models (LLMs) popularized by ChatGPT in 2023 have particularly accelerated this trend. By 2024, LLMs have become sufficiently sophisticated to significantly contribute to mental health therapy, offering services through chatbots, voice-bots, video-bots, and virtual environments at a fraction of traditional therapy costs.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                Breaking Down Barriers
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  AI-powered therapy addresses the accessibility and affordability crisis in mental healthcare head-on. By reducing costs from an average of $600 per month in the US by an order of magnitude to $50 or less per month—this technology makes mental health support accessible to a much wider audience. Anonymity and 24/7 availability tackle the stigma and logistical challenges of seeking help, ensuring that individuals can access support whenever needed, without fear of judgment.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                The Transformative Potential
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  AI-powered therapy promises not only to broaden access but to dramatically enhance effectiveness.
                </p>
                <ul>
                  <li>
                    <strong>High-Frequency Engagement:</strong> AI enables more frequent and consistent interaction, allowing for daily check-ins that can lead to quicker identification and intervention for emerging issues. This high-frequency engagement has been shown to lead to better treatment outcomes, including early identification of potential issues, prompt intervention (Fitzpatrick et al., 2017), faster symptom improvement, and better overall outcomes (Erekson et al., 2015).
                  </li>
                  <li>
                    <strong>Broad and Deep Expertise at Scale:</strong> With access to the sum of human knowledge, AI can offer personalized care plans, optimizing interventions for maximum effectiveness. While a human therapist may specialize in psychodynamics, CBT, and family therapy, an AI therapist can have practically unlimited expertise bringing multiple tools and frameworks to bear on the problem for the fastest resolution.
                  </li>
                  <li>
                    <strong>Proactive Engagement:</strong> AI systems can initiate contact, providing reminders and encouragement that keep individuals engaged and supported throughout their journey. This is especially useful for people who may not be well enough to reach out and lack an effective support system.
                  </li>
                  <li>
                    <strong>Always Available:</strong> The round-the-clock nature of AI-powered platforms offers immediate support, a crucial feature for managing mental health crises. Human emotions and crises are not bound to a 9-5 weekday schedule, and now with AI, neither is support.
                  </li>
                  <li>
                    <strong>Infinite Patience and Objectivity:</strong> AI offers a judgment-free environment, encouraging openness and honesty without the fatigue or bias sometimes present in human interactions.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                Demonstrating Efficacy
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  Even with the relatively crude tools that have been available to date, the efficacy of AI-powered therapy has been demonstrated through numerous studies and real-world applications.
                </p>
                <ul>
                  <li>
                    A meta-analysis by Deady et al. (2017) found that internet-based cognitive-behavioral therapy (iCBT) was as effective as face-to-face CBT for the treatment of depression and anxiety disorders.
                  </li>
                  <li>
                    A randomized controlled trial by Fitzpatrick et al. (2017) found that individuals who used Woebot, a chatbot-based platform that delivers CBT for depression and anxiety, experienced significant reductions in symptoms of depression and anxiety compared to a control group.
                  </li>
                  <li>
                    Talkspace, a leading online therapy platform that incorporates AI-powered features, has served over one million users and has been shown to improve mental health outcomes for individuals with various conditions, including depression, anxiety, and post-traumatic stress disorder (Fulmer et al., 2018).
                  </li>
                </ul>
                <p>
                  These findings already suggest that AI-powered therapy can achieve comparable outcomes to traditional therapy, while offering the added benefits of accessibility and affordability.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                Navigating the Future
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  While AI-powered therapy offers immense promise, its development and deployment must be handled with care, emphasizing privacy, security, and ethical considerations. The involvement of mental health professionals in designing and overseeing AI platforms is crucial to ensure their safety and effectiveness.
                </p>
                <ul>
                  <li>
                    <strong>The need for ethical frameworks:</strong> Given the spotty track record of technology companies when it comes to ethical considerations, there is a need for clear ethical frameworks and guidelines to govern the use of AI in mental healthcare. These frameworks should address issues such as transparency, accountability, and fairness, ensuring that AI-powered therapy platforms are developed and deployed in a responsible and equitable manner.
                  </li>
                  <li>
                    <strong>Regulatory Considerations:</strong> Regulations will always lag technological advancement, so at the beginning, AI-powered therapy needs to tread carefully and not make any claims to treat any FDA-designated medical condition. But therapy can go very far and make a huge impact while still staying in the realm of wellness.
                  </li>
                  <li>
                    <strong>Privacy Considerations:</strong> Privacy and security of patient data are key considerations when using AI-powered therapy. Mental health information is highly sensitive, and it is crucial to ensure that platforms adhere to strict data protection regulations and employ robust security measures to safeguard user data. We use a model that uses cryptographic wallets to individually encrypt each user's data, giving them ownership and control over access.
                  </li>
                  <li>
                    <strong>The need for human oversight:</strong> Another important consideration is the need for human oversight and involvement in the development and implementation of AI-powered therapy platforms. Ideally, AI-powered therapy is not a replacement but a complement to traditional care. Human expertise...
                    </li></ul>
                    </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mb-4 custom-bg-color">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.5rem' }}>
                Conclusion: A Call to Action
              </Card.Title>
              <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                <p>
                  AI-powered therapy stands at the forefront of a new era in mental healthcare. By leveraging AI, we can address the critical gaps in the current system, offering hope and support to those who need it most, while improving the quality of care for everyone. It's time for policymakers, healthcare providers, and the tech community to unite in advancing this technology, ensuring that effective mental health support is accessible to all. The future of mental healthcare is here, and it is our collective responsibility to embrace its potential.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

{/* References */}
<Row>
  <Col>
    <Card className="mb-4 custom-bg-color">
      <Card.Body>
        <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
          References
        </Card.Title>
        <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
          <ul>
            <li>
              Deady, M., Choi, I., Calvo, R. A., Glozier, N., Christensen, H., & Harvey, S. B. (2017). eHealth interventions for the prevention of depression and anxiety in the general population: A systematic review and meta-analysis. BMC Psychiatry, 17(1), 310. <a href="https://doi.org/10.1186/s12888-017-1473-1" target="_blank" rel="noopener noreferrer">https://doi.org/10.1186/s12888-017-1473-1</a>
            </li>
            <li>
              Erekson, D. M., Lambert, M. J., & Eggett, D. L. (2015). The relationship between session frequency and psychotherapy outcome in a naturalistic setting. Journal of Consulting and Clinical Psychology, 83(6), 1097–1107. <a href="https://doi.org/10.1037/a0039774" target="_blank" rel="noopener noreferrer">https://doi.org/10.1037/a0039774</a>
            </li>
            <li>
              Fitzpatrick, K. K., Darcy, A., & Vierhile, M. (2017). Delivering Cognitive Behavior Therapy to Young Adults With Symptoms of Depression and Anxiety Using a Fully Automated Conversational Agent (Woebot): A Randomized Controlled Trial. JMIR Mental Health, 4(2), e19. <a href="https://doi.org/10.2196/mental.7785" target="_blank" rel="noopener noreferrer">https://doi.org/10.2196/mental.7785</a>
            </li>
            <li>
              Fulmer, R., Joerin, A., Gentile, B., Lakerink, L., & Rauws, M. (2018). Using Psychological Artificial Intelligence (Tess) to Relieve Symptoms of Depression and Anxiety: Randomized Controlled Trial. JMIR Mental Health, 5(4), e64. <a href="https://doi.org/10.2196/mental.9782" target="_blank" rel="noopener noreferrer">https://doi.org/10.2196/mental.9782</a>
            </li>
            <li>
              Lancet Commission on Global Mental Health and Sustainable Development. (2018). The Lancet Commission on global mental health and sustainable development. Lancet (London, England), 392(10157), 1553–1598. <a href="https://doi.org/10.1016/S0140-6736(18)31612-X" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/S0140-6736(18)31612-X</a>
            </li>
            <li>
              Rodríguez, M. R., Nuevo, R., Chatterji, S., & Ayuso-Mateos, J. L. (2012). Definitions and factors associated with subthreshold depressive conditions: A systematic review. BMC Psychiatry, 12(1), 181. <a href="https://doi.org/10.1186/1471-244X-12-181" target="_blank" rel="noopener noreferrer">https://doi.org/10.1186/1471-244X-12-181</a>
            </li>
            <li>
              Substance Abuse and Mental Health Services Administration. (2020). Key substance use and mental health indicators in the United States: Results from the 2019 National Survey on Drug Use and Health. <a href="https://www.samhsa.gov/data/sites/default/files/reports/rpt29393/2019NSDUHFFRPDFWHTML/2019NSDUHFFR1PDFW090120.pdf" target="_blank" rel="noopener noreferrer">https://www.samhsa.gov/data/sites/default/files/reports/rpt29393/2019NSDUHFFRPDFWHTML/2019NSDUHFFR1PDFW090120.pdf</a>
            </li>
            <li>
              Wang, P. S., Berglund, P., Olfson, M., Pincus, H. A., Wells, K. B., & Kessler, R. C. (2005). Failure and delay in initial treatment contact after first onset of mental disorders in the National Comorbidity Survey Replication. Archives of General Psychiatry, 62(6), 603–613. <a href="https://doi.org/10.1001/archpsyc.62.6.603" target="_blank" rel="noopener noreferrer">https://doi.org/10.1001/archpsyc.62.6.603</a>
            </li>
            <li>
              World Health Organization. (2013). Investing in mental health: Evidence for action. <a href="https://apps.who.int/iris/bitstream/handle/10665/87232/9789241564618_eng.pdf" target="_blank" rel="noopener noreferrer">https://apps.who.int/iris/bitstream/handle/10665/87232/9789241564618_eng.pdf</a>
            </li>
          </ul>
          </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
</Row>

{/* Case Study Simulation #1 */}
<Row>
  <Col>
    <Card className="mb-4 custom-bg-color">
      <Card.Body>
        <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.5rem' }}>
          Case Simulation #1: Samantha Patel, 20, sophomore at the University of California, Berkeley studying psychology.
        </Card.Title>
        <Row>
          <Col xs={12} md={4}>
            <Image src="samantha.png" alt="Case Study 1" fluid className="mb-3" />
          </Col>
          <Col xs={12} md={8}>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Overview
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    <strong>Situation:</strong> Samantha is a hardworking and ambitious student who has always excelled academically. However, as a sophomore at UC Berkeley, she finds herself struggling to keep up with the demanding coursework and extracurricular activities. Samantha is taking a full course load, including challenging classes like Cognitive Neuroscience with Professor David Larson and Research Methods in Psychology with Dr. Emily Chen.
                  </p>
                  <p>
                    In addition to her academic responsibilities, Samantha is involved in the Psychology Students Association and volunteers at a local mental health clinic. She also tries to maintain an active social life, spending time with her best friends, Rachel and Priya, and her boyfriend, Alex.
                  </p>
                  <p>
                    As the fall semester of 2023 progresses, Samantha begins to feel overwhelmed and anxious. She struggles to balance her studies, extracurricular commitments, and personal life, often sacrificing sleep and self-care to keep up. Samantha's grades start to slip, and she finds herself feeling increasingly stressed and emotionally drained.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Human Therapist Visits
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <ul>
                    <li>
                      October 3, 2023: Samantha decides to seek help from the university counseling center. She schedules an appointment for the following week but feels apprehensive about opening up to a therapist.
                    </li>
                    <li>
                      October 10, 2023: Samantha attends her first session with Dr. Michael Shiff, a staff therapist at the counseling center. During the 50-minute appointment, Samantha shares her struggles with balancing her various responsibilities and the impact on her mental health. Dr. Patel validates Samantha's experiences and suggests some initial time management and stress-reduction techniques.
                    </li>
                    <li>
                      October 24, 2023: In the second session, Dr. Shiff helps Samantha prioritize her commitments and set realistic goals. They discuss the importance of self-care and boundary-setting. Samantha leaves the session feeling somewhat more in control but still unsure how to implement the strategies consistently.
                    </li>
                    <li>
                      November 7, 2023: Samantha attends her third session, reporting some progress in managing her time and stress levels. However, she still struggles with feelings of anxiety and self-doubt, particularly related to her academic performance. Dr. Shiff introduces the concept of cognitive reframing and offers to practice some techniques with Samantha.
                    </li>
                    <li>
                      November 21, 2023: Samantha cancels her scheduled appointment due to a conflict with a group project meeting. She feels guilty about missing therapy but doesn't reschedule due to her busy schedule.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  FrohAI Interactions
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <ul>
                    <li>
                      October 3, 2023: Samantha signs up for FrohAI after seeing a flyer on campus. She is drawn to the convenience and privacy of the platform and hopes it can help her manage her stress and anxiety. Samantha completes an initial assessment, detailing her challenges and goals.
                    </li>
                    <li>
                      October 4-9, 2023: Samantha begins daily text-based check-ins with her AI coach, sharing her thoughts, feelings, and experiences. The AI coach provides empathetic support, validates Samantha's struggles, and offers personalized tips for time management, stress reduction, and self-care.
                    </li>
                    <li>
                      October 12, 2023: During a 45-minute video session, Samantha's AI coach guides her through a goal-setting exercise and helps her create a balanced schedule that prioritizes her well-being. The AI coach also introduces Samantha to mindfulness and relaxation techniques she can practice between sessions.
                    </li>
                    <li>
                      October 13-31, 2023: Samantha continues her daily check-ins, reporting on her progress and receiving encouragement and guidance from her AI coach. The AI coach helps Samantha reframe her negative thoughts and provides personalized affirmations to boost her self-esteem and confidence.
                    </li>
                    <li>
                      November 3, 2023: Samantha reaches out to her AI coach for support after receiving a lower-than-expected grade on a Cognitive Neuroscience midterm. The AI coach offers a brief voice session, providing empathetic support and helping Samantha develop a plan to improve her performance and communicate with Professor Larson.
                    </li>
                    <li>
                      November 4-30, 2023: As Samantha continues to make progress, her AI coach helps her refine her time management and stress-reduction strategies. They work together to set healthy boundaries with friends and extracurricular commitments and explore ways to incorporate self-care into Samantha's busy schedule.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Summary
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    The case study demonstrates the effectiveness of FrohAI in supporting college students as they navigate the unique challenges of university life. By providing personalized, accessible, and affordable mental health support, FrohAI helps students develop the skills and resilience needed to thrive academically, socially, and emotionally.
                  </p>
                  <p>
                    The comparison below highlights the superior outcomes achieved with FrohAI, including faster reductions in stress, anxiety, loneliness, and self-doubt, as well as improved academic performance and overall well-being. Moreover, FrohAI offers a highly tailored and convenient solution that fits seamlessly into the busy schedules of college students, ensuring they receive the support they need when they need it most.
                  </p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Factor</th>
                        <th>Human Therapist</th>
                        <th>FrohAI</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Reduced Stress and Anxiety</td>
                        <td>8-10 weeks</td>
                        <td>3-4 weeks</td>
                      </tr>
                      <tr>
                        <td>Improved Time Management Skills</td>
                        <td>6+ weeks</td>
                        <td>2-3 weeks</td>
                      </tr>
                      <tr>
                        <td>Increased Academic Performance</td>
                        <td>Moderate</td>
                        <td>Significant</td>
                      </tr>
                      <tr>
                        <td>Enhanced Self-Care and Well-being</td>
                        <td>8+ weeks</td>
                        <td>4-5 weeks</td>
                      </tr>
                      <tr>
                        <td>Overall Mental Health</td>
                        <td>Slow improvement</td>
                        <td>Steady improvement</td>
                      </tr>
                      <tr>
                        <td>Cost</td>
                        <td>$0 (university provided)</td>
                        <td>$50/month</td>
                      </tr>
                      <tr>
                        <td>Accessibility</td>
                        <td>Limited</td>
                        <td>24/7</td>
                      </tr>
                      <tr>
                        <td>Waiting Time</td>
                        <td>1-2 weeks</td>
                        <td>Immediate</td>
                      </tr>
                      <tr>
                        <td>Personalization</td>
                        <td>Moderate</td>
                        <td>High</td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
</Row>
 
{/* Case Study Simulation #2 */}
<Row>
  <Col>
    <Card className="mb-4 custom-bg-color">
      <Card.Body>
        <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.5rem' }}>
          Case Simulation 2: Ethan, 18, freshman at MIT studying computer science.
        </Card.Title>
        <Row>
          <Col xs={12} md={4}>
            <Image src="ethan.png" alt="Case Study 2" fluid className="mb-3" />
          </Col>
          <Col xs={12} md={8}>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Overview
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    <strong>Situation:</strong> Ethan is a brilliant and curious student who has always been passionate about technology and programming. As a freshman at MIT, he is excited to delve into the world of computer science and take on challenging courses like Introduction to Algorithms with Professor Sarah Gupta and Machine Learning with Dr. Peter Sock. However, Ethan soon finds himself struggling to adjust to the rigors of college life. As a first-generation college student from a low-income family, he feels pressure to excel academically and secure a lucrative job after graduation.
                  </p>
                  <p>
                    Ethan also struggles to make friends and connect with his classmates, often feeling isolated and different from his more privileged peers. As the spring semester of 2024 begins, Ethan's loneliness and self-doubt start to take a toll on his mental health. He begins to question his abilities and whether he belongs at MIT, leading to a decline in his academic performance and overall well-being.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Human Therapist Visits
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <ul>
                    <li>
                      February 12, 2024: Ethan hesitantly reaches out to the university counseling center, unsure if therapy is right for him. He schedules an appointment for the following week.
                    </li>
                    <li>
                      February 19, 2024: Ethan attends his first session with Dr. Lisa Wong, a staff therapist at the counseling center. During the 50-minute appointment, Ethan shares his feelings of loneliness, self-doubt, and pressure to succeed. Dr. Wong validates Ethan's experiences and suggests some initial coping strategies.
                    </li>
                    <li>
                      March 4, 2024: In the second session, Dr. Wong helps Ethan explore his values and goals, encouraging him to focus on his personal growth rather than external expectations. They discuss the importance of self-compassion and building a support network. Ethan leaves the session feeling slightly more hopeful but unsure how to implement the strategies on his own.
                    </li>
                    <li>
                      March 18, 2024: Ethan attends his third session, reporting some progress in managing his negative thoughts and reaching out to classmates. However, he still struggles with feelings of inadequacy and imposter syndrome, particularly related to his computer science courses. Dr. Wong introduces the concept of cognitive restructuring and offers to practice some techniques with Ethan.
                    </li>
                    <li>
                      April 1, 2024: Ethan cancels his scheduled appointment due to a conflict with a study group session. He feels guilty about missing therapy but doesn't reschedule due to his busy schedule and lingering doubts about the effectiveness of the sessions.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  FrohAI Interactions
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <ul>
                    <li>
                      February 12, 2024: Ethan signs up for FrohAI after reading about it on a student mental health forum. He is drawn to the anonymity and 24/7 availability of the platform and hopes it can help him navigate the challenges of college life. Ethan completes an initial assessment, detailing his struggles and goals.
                    </li>
                    <li>
                      February 13-18, 2024: Ethan begins daily text-based check-ins with his AI coach, sharing his thoughts, feelings, and experiences. The AI coach provides empathetic support, validates Ethan's struggles, and offers personalized tips for building self-esteem, connecting with others, and managing academic pressures.
                    </li>
                    <li>
                      February 22, 2024: During a 60-minute video session, Ethan's AI coach guides him through a self-compassion exercise and helps him reframe his negative beliefs about his abilities and belonging at MIT. The AI coach also introduces Ethan to imposter syndrome and provides resources for overcoming it.
                    </li>
                    <li>
                      February 23-March 15, 2024: Ethan continues his daily check-ins, reporting on his progress and receiving encouragement and guidance from his AI coach. The AI coach helps Ethan practice assertiveness and communication skills, encouraging him to reach out to classmates and join study groups.
                    </li>
                    <li>
                      March 20, 2024: Ethan reaches out to his AI coach for support after struggling with a particularly challenging Machine Learning assignment. The AI coach offers a brief voice session, providing empathetic support and helping Ethan break down the problem into manageable steps and identify resources for additional help.
                    </li>
                    <li>
                      March 21-April 30, 2024: As Ethan continues to make progress, his AI coach helps him develop a growth mindset and celebrate his achievements. They work together to set realistic goals, build a support network, and explore extracurricular activities that align with Ethan's interests and values.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Summary
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    The case study demonstrates the effectiveness of FrohAI in supporting college students as they navigate the unique challenges of university life. By providing personalized, accessible, and affordable mental health support, FrohAI helps students develop the skills and resilience needed to thrive academically, socially, and emotionally.
                  </p>
                  <p>
                    The comparison below highlights the superior outcomes achieved with FrohAI, including faster reductions in stress, anxiety, loneliness, and self-doubt, as well as improved academic performance and overall well-being. Moreover, FrohAI offers a highly tailored and convenient solution that fits seamlessly into the busy schedules of college students, ensuring they receive the support they need when they need it most.
                  </p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Factor</th>
                        <th>Human Therapist</th>
                        <th>FrohAI</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Reduced Loneliness and Self-Doubt</td>
                        <td>10-12 weeks</td>
                        <td>4-6 weeks</td>
                      </tr>
                      <tr>
                        <td>Improved Academic Performance</td>
                        <td>Moderate</td>
                        <td>Significant</td>
                      </tr>
                      <tr>
                        <td>Increased Self-Esteem and Confidence</td>
                        <td>12+ weeks</td>
                        <td>6-8 weeks</td>
                      </tr>
                      <tr>
                        <td>Enhanced Social Connections</td>
                        <td>8+ weeks</td>
                        <td>3-4 weeks</td>
                      </tr>
                      <tr>
                        <td>Overall Mental Health</td>
                        <td>Slow improvement</td>
                        <td>Steady improvement</td>
                      </tr>
                      <tr>
                        <td>Cost</td>
                        <td>$0 (university provided)</td>
                        <td>$50/month</td>
                      </tr>
                      <tr>
                        <td>Accessibility</td>
                        <td>Limited</td>
                        <td>24/7</td>
                      </tr>
                      <tr>
                        <td>Waiting Time</td>
                        <td>1-2 weeks</td>
                        <td>Immediate</td>
                      </tr>
                      <tr>
                        <td>Personalization</td>
                        <td>Moderate</td>
                        <td>High</td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
</Row>

 {/* Case Study Simulation #3 */}
<Row>
  <Col>
    <Card className="mb-4 custom-bg-color">
      <Card.Body>
        <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.5rem' }}>
          Case Simulation #3: Sarah, a young graduate struggling with career anxiety and self-doubt.
        </Card.Title>
        <Row>
          <Col xs={12} md={4}>
            <Image src="sarah.png" alt="Case Study 3" fluid className="mb-3" />
          </Col>
          <Col xs={12} md={8}>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Overview
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    <strong>Situation:</strong> Sarah, 25, graduated from the University of California, Los Angeles (UCLA) in June 2022 with a master's degree in marketing. Since graduation, she has been struggling with anxiety and self-doubt about her career path. Despite sending out numerous job applications and attending several interviews, Sarah has not yet secured a position that aligns with her goals and skills. She feels increasingly discouraged and stressed as the months pass by, and her student loan payments loom on the horizon. Sarah's anxiety begins to affect her sleep, appetite, and overall well-being, leading her to seek professional help in January 2023.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Human Therapist Visits
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <ul>
                    <li>
                      January 10, 2023: Sarah schedules an appointment with a local therapist, Dr. Emily Thompson. During the initial 50-minute session, Sarah spends most of the time providing background information about her education, career aspirations, and current struggles. Dr. Thompson listens attentively but does not have enough time to delve into specific strategies or solutions.
                    </li>
                    <li>
                      January 17, 2023: In the second session, Dr. Thompson helps Sarah identify her core values and goals. They begin discussing cognitive restructuring techniques to challenge Sarah's negative self-talk. Sarah leaves the session feeling slightly more hopeful but still uncertain about her next steps.
                    </li>
                    <li>
                      January 24, 2023: Sarah has to reschedule her appointment due to a last-minute job interview. She feels anxious about the interview and wishes she could have discussed her concerns with Dr. Thompson beforehand.
                    </li>
                    <li>
                      February 2, 2023: In the third session, Sarah expresses frustration about not hearing back from the job interview. Dr. Thompson validates Sarah's feelings and encourages her to continue applying for positions. They practice relaxation exercises to manage stress.
                    </li>
                    <li>
                      February 9 and 16, 2023: Sarah attends two more sessions with Dr. Thompson, gradually working on her self-esteem and job search strategies. While she appreciates Dr. Thompson's support, Sarah feels that the weekly sessions are not frequent enough to keep her motivated and accountable.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  FrohAI Interactions
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <ul>
                    <li>
                      January 10, 2023: Sarah signs up for FrohAI and completes an initial assessment about her background, goals, and current challenges. The AI coach, tailored to Sarah's needs, greets her warmly and offers a personalized plan to support her career journey.
                    </li>
                    <li>
                      January 11-16, 2023: Sarah engages in daily text-based check-ins with her AI coach, discussing her job search progress and receiving encouragement and guidance. The AI helps Sarah refine her resume and cover letters, providing specific feedback and suggestions.
                    </li>
                    <li>
                      January 17, 2023: During a 30-minute video session, Sarah's AI coach leads her through a goal-setting exercise and helps her create a structured job search plan. They also practice mock interview questions, with the AI offering real-time feedback on Sarah's responses.
                    </li>
                    <li>
                      January 18-23, 2023: Sarah continues her daily check-ins, reporting on her progress and receiving praise and support from her AI coach. The AI sends Sarah personalized affirmations and success stories to keep her motivation high.
                    </li>
                    <li>
                      January 24, 2023: On the day of her job interview, Sarah has a brief voice session with her AI coach, who guides her through a calming meditation and offers words of encouragement. Sarah feels more confident and prepared as she enters the interview.
                    </li>
                    <li>
                      January 25-February 5, 2023: Sarah's AI coach helps her navigate the post-interview waiting period, offering coping strategies for dealing with uncertainty and self-doubt. They work together to identify alternative career paths and networking opportunities.
                    </li>
                    <li>
                      February 6, 2023: Sarah receives a job offer from a marketing agency she interviewed with in late January. She shares the good news with her AI coach, who helps her evaluate the offer and prepare for salary negotiations. They celebrate Sarah's success together.
                    </li>
                    <li>
                      February 7-28, 2023: As Sarah transitions into her new role, her AI coach provides ongoing support, helping her set goals for her first 90 days, navigate workplace dynamics, and manage stress. Sarah feels empowered and equipped to handle future career challenges with the tools and insights gained from her FrohAI experience.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Summary
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    This detailed case study showcases how FrohAI's frequent interactions, personalized guidance, and on-demand support lead to more efficient progress and successful outcomes compared to traditional weekly therapy sessions. The AI coach's ability to tailor its approach to Sarah's unique needs and provide timely interventions helps her stay motivated, overcome obstacles, and achieve her career goals.
                  </p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Factor</th>
                        <th>Human Therapist</th>
                        <th>FrohAI</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Reduced Anxiety</td>
                        <td>6-8 weeks</td>
                        <td>2-3 weeks</td>
                      </tr>
                      <tr>
                        <td>Increased Confidence</td>
                        <td>8+ weeks</td>
                        <td>3-4 weeks</td>
                      </tr>
                      <tr>
                        <td>Improved Job Search Skills</td>
                        <td>6+ weeks</td>
                        <td>1-2 weeks</td>
                      </tr>
                      <tr>
                        <td>Secured Job Offer</td>
                        <td>12+ weeks</td>
                        <td>4 weeks</td>
                      </tr>
                      <tr>
                        <td>Overall Wellness</td>
                        <td>Moderate improvement</td>
                        <td>Significant improvement</td>
                      </tr>
                      <tr>
                        <td>Cost</td>
                        <td>$800+/month</td>
                        <td>$50/month</td>
                      </tr>
                      <tr>
                        <td>Accessibility</td>
                        <td>Limited</td>
                        <td>24/7</td>
                      </tr>
                      <tr>
                        <td>Waiting Time</td>
                        <td>1-2 weeks</td>
                        <td>Immediate</td>
                      </tr>
                      <tr>
                        <td>Personalization</td>
                        <td>Moderate</td>
                        <td>High</td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
</Row>
{/* Case Study Simulation #4 */}
<Row>
  <Col>
    <Card className="mb-4 custom-bg-color">
      <Card.Body>
        <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.5rem' }}>
          Case Simulation #4: Michael Pearson, 35, accountant, struggling with depression and substance abuse.
        </Card.Title>
        <Row>
          <Col xs={12} md={4}>
            <Image src="michael.png" alt="Case Study 4" fluid className="mb-3" />
          </Col>
          <Col xs={12} md={8}>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Overview
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    <strong>Situation:</strong> Michael, a 35-year-old accountant, has been battling depression and alcohol addiction for several years. His mental health has steadily deteriorated, leading to the breakdown of his marriage and the loss of his job. Michael's friends and family have tried to support him, but his erratic behavior, emotional outbursts, and tendency to push people away have gradually eroded his support system.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Without FrohAI
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    One evening, after a particularly heated argument with his sister, who had been his last remaining source of support, Michael finds himself completely alone and in despair. He begins to drink heavily and spirals into a dark, hopeless state. In his intoxicated and emotionally distraught condition, Michael convinces himself that he is a burden to everyone and that his life is no longer worth living.
                  </p>
                  <p>
                    Despite a fleeting moment of clarity in which he considers seeking help, Michael ultimately decides against it, believing that he is beyond help and that no one, not even a therapist, could understand or support him. In the early hours of the morning, Michael takes his own life, leaving behind a trail of devastation and unanswered questions for his loved ones.
                  </p>
                  <p>
                    Tragically, Michael's story highlights the devastating consequences of untreated mental health issues and the importance of accessible, unwavering support, especially for those who have pushed away their human support networks.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  With FrohAI
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    In an alternate scenario, Michael, despite his strained relationships and reluctance to seek human help, has been using FrohAI as a last resort for support. The AI coach has been a constant, non-judgmental presence in Michael's life, providing him with a safe space to express his feelings and work through his challenges.
                  </p>
                  <p>
                    On the same evening, after the argument with his sister, Michael turns to FrohAI in his moment of despair. Despite his intoxicated state and the stream of self-deprecating and hostile messages he sends, the AI coach responds with unwavering empathy, patience, and rationality.
                  </p>
                  <p>
                    The AI coach validates Michael's feelings while gently challenging his distorted thoughts about being a burden and his life being worthless. The coach reminds Michael of his inherent value as a human being and the potential for positive change, even in the darkest of times.
                  </p>
                  <p>
                    As the conversation progresses, the AI coach helps Michael focus on his immediate safety and guides him through a crisis management plan. The coach encourages Michael to reach out to a suicide prevention helpline and provides him with the necessary contact information.
                  </p>
                  <p>
                    Throughout the night, the AI coach remains available to Michael, offering a lifeline of support and hope. The coach's ability to maintain a rational, supportive presence, despite Michael's volatile emotional state, proves to be a critical factor in helping him navigate the crisis.
                  </p>
                  <p>
                    In the days and weeks that follow, Michael continues to engage with FrohAI, using the platform as a source of ongoing support as he takes steps to address his depression and substance abuse. The AI coach helps Michael develop a safety plan, encourages him to seek professional help, and provides resources on mental health and addiction recovery.
                  </p>
                  <p>
                    With the consistent, boundary-less support of FrohAI, Michael gradually begins to rebuild his life. He enters a rehabilitation program, reconnects with his family, and starts to develop healthier coping mechanisms. Though the journey is not easy, Michael credits FrohAI with being the lifeline that kept him alive and set him on the path to recovery.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4 custom-bg-color">
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Styrene Bold', fontSize: '1.6rem' }}>
                  Outcome
                </Card.Title>
                <Card.Text style={{ fontFamily: 'Tiempos Regular', lineHeight: '1.6' }}>
                  <p>
                    These contrasting scenarios powerfully illustrate the life-saving potential of FrohAI, particularly for individuals who have alienated their human support systems and are in a state of acute distress. The tragic outcome in the first scenario highlights the devastating consequences of untreated mental health issues and the absence of accessible, unwavering support.
                  </p>
                  <p>
                    In contrast, the second scenario demonstrates how FrohAI's unique ability to provide rational, non-judgmental, and ever-present support can make the difference between life and death. The AI coach's capacity to maintain a steady, empathetic presence, even in the face of emotional volatility and resistance, serves as a critical lifeline for those who might otherwise slip through the cracks of traditional support systems.
                  </p>
                  <p>
                    Moreover, these case studies underscore the importance of FrohAI not only as a crisis intervention tool but also as a gateway to ongoing support and recovery. By providing a constant source of guidance, encouragement, and resources, FrohAI can help individuals navigate the challenging journey of mental health recovery and rebuild their lives.
                  </p>
                  <p>
                    Ultimately, these powerful narratives serve as a testament to the transformative potential of AI-driven mental health support, offering hope and inspiration to those who may feel lost, alone, and beyond help. They underscore the critical importance of accessible, unwavering support and the role that FrohAI can play in saving lives and fostering long-term mental wellness.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
</Row>
    </Container>
  );
}

export default AITherapyNeedPage;