// AcceptableUsePolicyPage.js
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import './PolicyPage.css';

function AcceptableUsePolicyPage() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container className="mt-4 mb-4">
      <Card className="mb-4 custom-bg-color">
        <Card.Body>
          <div
            name="termly-embed"
            data-id="8343db9f-915a-4e48-92a2-2b34ffcd7211"
          ></div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default AcceptableUsePolicyPage;