// DisclaimerPage.js
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import './PolicyPage.css';

function DisclaimerPage() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container className="mt-4 mb-4">
      <Card className="mb-4 custom-bg-color">
        <Card.Body>
          <div
            name="termly-embed"
            data-id="ade384eb-a3ab-4a45-9e88-59d6907641f4"
          ></div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default DisclaimerPage;