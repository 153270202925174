// VoiceTherapySession.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Container, Button, Spinner } from 'react-bootstrap';

function VoiceTherapySession({ userState, setUserState }) {
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isIPhone, setIsIPhone] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioRef = useRef(null);

  const handleTextToSpeech = useCallback(async (text) => {
    console.log('Converting text to speech:', text);
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/audio/speech',
        {
          model: 'tts-1',
          input: text,
          voice: userState.voiceName,
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      );
      const audioBlob = await response.data;
      console.log('Audio blob received:', audioBlob);
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      await audioRef.current.play();
      console.log('Audio playback started.');
    } catch (error) {
      console.error('Error in text-to-speech:', error);
      // Handle the error, show an error message, or fallback to a different approach
    }
  }, [userState.voiceName]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsIPhone(/iPhone/i.test(userAgent));
  
    const { messages } = userState;
    if (messages && messages.length > 0 && messages[messages.length - 1].sender === 'assistant') {
      const isLatestMessageUnreplied = messages.length === 1 || messages[messages.length - 2].sender === 'assistant';
      if (isLatestMessageUnreplied) {
        handleTextToSpeech(messages[messages.length - 1].text);
      }
    }
  }, [userState, handleTextToSpeech]);

  const startRecording = async () => {
    console.log('Starting recording...');
    if (!isRecording) {
      setIsRecording(true);
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            sampleRate: 44100,
            channelCount: 1,
            sampleSize: 16,
          },
        });
        console.log('Audio stream obtained:', stream);
        const recorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        const chunks = [];
        recorder.addEventListener('dataavailable', (event) => {
          console.log('Data available:', event.data);
          chunks.push(event.data);
        });
        recorder.addEventListener('stop', async () => {
          console.log('Recording stopped.');
          const blob = new Blob(chunks, { type: 'audio/webm' });
          console.log('Audio blob created:', blob);
          const audioUrl = URL.createObjectURL(blob);
          audioRef.current.src = audioUrl;
          await transcribeAudio(blob);
        });
        recorder.start();
        mediaRecorderRef.current = recorder;
        console.log('MediaRecorder started.');
      } catch (error) {
        console.error('Error starting recording:', error);
        setIsRecording(false);
        // Handle the error, show an error message, or fallback to a different approach
      }
    }
  };

  const stopRecording = () => {
    console.log('Stopping recording...');
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      console.log('MediaRecorder stopped.');
    }
  };

  const transcribeAudio = async (audioBlob) => {
    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.webm');
    console.log('Audio blob size:', audioBlob.size);
    formData.append('model', 'whisper-1');
    try {
      setIsLoading(true);
      const transcriptionResponse = await axios.post(
        'https://api.openai.com/v1/audio/transcriptions',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const userMessage = transcriptionResponse.data.text;
      console.log('Transcribed text:', userMessage);
      const newMessage = { text: userMessage, sender: 'user' };
      setUserState((prevState) => ({
        ...prevState,
        messages: [...(prevState.messages || []), newMessage],
      }));
      // Send the transcribed text to the backend API
      const botResponse = await axios.post(`${process.env.REACT_APP_API_URL}`, {
        userId: userState.visitorId,
        message: userMessage,
        agentId: userState.agentId,
     });

      const assistantMessage = botResponse.data.message;
      const newAssistantMessage = { text: assistantMessage, sender: 'assistant' };
      setUserState((prevState) => ({
        ...prevState,
        messages: [...(prevState.messages || []), newAssistantMessage],
      }));
      await handleTextToSpeech(assistantMessage);
      setIsLoading(false);
    } catch (error) {
      console.error('Error in speech-to-text:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error details:', error.response.data.error);
      }
      setIsLoading(false);
      // Handle the error, show an error message, or fallback to a different approach
    }
  };

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#f4f3ee', minHeight: '80vh' }}>
      {isIPhone && (
        <div className="text-center mb-3">
          <span style={{ fontSize: '1.2rem', color: 'red' }}>Sorry, voice interface does not work on the Iphone due to Apple policy. Use a desktop or Android, or switch to text. Our iOS app is coming soon.</span>
        </div>
      )}
      <Button
        onMouseDown={startRecording}
        onMouseUp={stopRecording}
        onTouchStart={startRecording}
        onTouchEnd={stopRecording}
        variant={isRecording ? 'success' : 'danger'}
        style={{ width: 200, height: 200, borderRadius: 100, position: 'relative' }}
      >
        {isLoading ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Spinner animation="border" variant="light" />
            </div>
            <span style={{ fontSize: '1.2rem', color: 'white', marginTop: '60px' }}>Thinking</span>
          </div>
        ) : (
          <span style={{ fontSize: '1.2rem' }}>{isRecording ? 'Listening' : 'Press to Talk'}</span>
        )}
      </Button>
      <audio ref={audioRef} />
    </Container>
  );
}

export default VoiceTherapySession;