// TherapySessionPage.js
import React from 'react';
import VoiceTherapySession from './VoiceTherapySession';
import TextTherapySession from './TextTherapySession';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

function TherapySessionPage({ therapySessionType, setTherapySessionType, userState, setUserState }) {
  const toggleSession = () => {
    setTherapySessionType(therapySessionType === 'voice' ? 'text' : 'voice');
  };

  const getTopicName = () => {
    const { agentId } = userState;
    if (agentId) {
      const words = agentId.split('_');
      const capitalizedWords = words.map(word => {
        if (word === 'and') {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(' ');
    }
    return 'General Therapy';
  };

  const topicName = getTopicName();

  return (
    <Container className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-center mt-4 mb-3">
        <Button
          variant="outline-secondary"
          onClick={toggleSession}
          style={{ marginRight: '10px' }}
        >
          {therapySessionType === 'voice' ? 'Switch to Text' : 'Switch to Voice'}
        </Button>
        <span style={{ fontSize: '18px', color: '#6c757d' }}>Focus: {topicName}</span>
      </div>
      
      {therapySessionType === 'voice' ? (
        <VoiceTherapySession userState={userState} setUserState={setUserState} topicName={topicName} />
      ) : (
        <TextTherapySession userState={userState} setUserState={setUserState} />
      )}
    </Container>
  );
}

export default TherapySessionPage;