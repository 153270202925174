// TextTherapySession.js
import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "./TextTherapySession.css";

function TextTherapySession({ userState, setUserState }) {
  const [inputText, setInputText] = useState("");
  const chatContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [userState.messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const sendMessage = async () => {
    if (inputText.trim()) {
      const newMessage = { text: inputText, sender: 'user' };
      setUserState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, newMessage],
      }));
      setInputText('');

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userState.visitorId,
            message: inputText,
            agentId: userState.agentId,
          }),
        });

        const data = await response.json();
        if (data.message) {
          setUserState((prevState) => ({
            ...prevState,
            messages: [...prevState.messages, { text: data.message, sender: 'response' }],
          }));
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  return (
    <Container className="p-0">
      <Row className="justify-content-center">
        <Col md={10} lg={8} xl={6}>
          <Card className="chat-card" style={{ borderRadius: "15px" }}>
            <Card.Body
              className="chat-body"
              style={{ height: "450px" }}
              ref={chatContainerRef}
            >
              {userState.messages.map((message, index) => (
                <div
                  key={index}
                  className={`d-flex flex-row ${
                    message.sender === "user"
                      ? "justify-content-end"
                      : "justify-content-start"
                  } mb-3`}
                >
                  <div
                    className={`chat-bubble ${
                      message.sender === "user"
                        ? "user-bubble"
                        : "assistant-bubble"
                    }`}
                  >
                    {message.text}
                    <div className="bubble-arrow"></div>
                  </div>
                </div>
              ))}
            </Card.Body>
            <Card.Footer className="chat-footer">
              <Form.Control
                type="text"
                placeholder="Type a message"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
                className="message-input"
              />
              <Button variant="link" className="send-button" onClick={sendMessage}>
                <ArrowRightCircle size={24} />
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default TextTherapySession;